//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'menu-buttons',
  props: {
    menus: Array,
  },
  data() {
    return {
      icon: 'https://a.storyblok.com/f/127749/x/4e49cdd65f/acrobat-outline.svg'
    }
  },
  computed: {
    ...mapState([ 'order' ]),
  }
}
