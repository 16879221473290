//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isMenuHero: false,
    }
  },
  mounted() {
    const path = this.$route.path.replace(/\//g, '')
    if (path == 'order' || path == 'menu') {
      this.isMenuHero = true
    }
  },
  computed: {
    ...mapState([ 'order', 'location' ]),
    orderType() {
      if (this.order && this.order.handoff === ('dispatch' || 'delivery')) {
        return 'delivery'
      } else if (this.order && this.order.handoff === 'pickup') {
        return 'pickup'
      } else {
        return null
      }
    },
    selectedLocation() {
      return this.location.city + ', ' + this.location.state
    },
    menus() {
      const menus = []
      if(this.blok.main_menu.filename) {
        menus.push({name: 'Main Menu', filename: this.blok.main_menu.filename});
      }
      if(this.blok.kids_menu.filename) {
        menus.push({name: 'Kids Menu', filename: this.blok.kids_menu.filename});
      }
      if(this.blok.drinks_menu.filename) {
        menus.push({name: 'Drinks Menu', filename: this.blok.drinks_menu.filename});
      }
      if(this.blok.lunch_menu.filename) {
        menus.push({name: 'Lunch Menu', filename: this.blok.lunch_menu.filename});
      }
      if(this.blok.happyhour_menu.filename) {
        menus.push({name: 'Happy Hour', filename: this.blok.happyhour_menu.filename});
      }
      if(this.blok.limited_time_menu.filename) {
        menus.push({name: 'Limited Time', filename: this.blok.limited_time_menu.filename});
      }
      if(this.blok.specials_menu.filename) {
        menus.push({name: 'Specials', filename: this.blok.specials_menu.filename});
      }
      return menus
    }
  },
  methods: {
    setHandoff(type) {
      if (type != 'pickup') {
        this.$store.commit('setHandoff', this.location.services.dispatch ? 'dispatch' : 'delivery')
      } else {
        this.$store.commit('setHandoff', type)
      }
    }
  }
}
